import React from 'react';
import FutureGroup from '../components/cases/FutureGroup';

const Page = (): JSX.Element => (
  <>
    <FutureGroup />
  </>
);

export default Page;
