import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import Grid from '../../common/Grid';
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import ImageRow from './ImageRow';
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/futuregroup/branding/1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/futuregroup/branding/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/futuregroup/branding/3.webp" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/futuregroup/branding/4.webp" }) {
      ...fluidImage
    }
    image5: file(relativePath: { eq: "cases/futuregroup/branding/5.webp" }) {
      ...fluidImage
    }
  }
  `;

const FutureGroup = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = ['Konseptutvikling', 'Grafisk design', 'Softwareutvikling', 'UX / UI', 'AR / VR'];

  return (
    <Layout darkMode={true} className={styles.container}>
      <TopSection project={"The Future Universe platform"} deliveries={deliveries}>
        <p>
          <b>The Future Group</b>  er et internasjonalt prisbelønnet selskap innen teknologi og kreative tjenester. De tilbyr i dag neste generajon produkter og tjenester for virtuell produksjon som muliggjør fotorealistisk grafikk og visuelle effekter i sanntid.
        </p>
      </TopSection>
      <NonStretchedImage {...data.image1.childImageSharp} />
      <TextBlock header={"Om prosjektet"} bottom={true}>
        <p>
          Cras mattis consectetur purus sit amet fermentum.
          Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <p>
          Cras justo odio, dapibus ac facilisis in, egestas eget quam.
          Nulla vitae elit libero, a pharetra augue. Aenean lacinia bibendum nulla sed consectetur.
          Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
          Etiam porta sem malesda magna mollis euismod.
        </p>
      </TextBlock>
      <NonStretchedImage {...data.image2.childImageSharp}/>
      <ImageRow/>
      <NonStretchedImage {...data.image3.childImageSharp}/>
      <TextBlock wide={true} bottom={true}>
        <p>
          Cras mattis consectetur purus sit amet fermentum.
          Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
      </TextBlock>
      <NonStretchedImage {...data.image4.childImageSharp}/>
      <Grid>
        <div className={styles.imageWrapper}>
          <NonStretchedImage {...data.image5.childImageSharp} />
          <p>Illustrasjon / Storm Studio</p>
        </div>
      </Grid>
      <Nav/>
    </Layout>
  )
};

export default FutureGroup;

